<template>
  <div>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      @click:outside="close"
      @keydown.e.esc="close"
    >
      <v-card>
        <v-progress-linear
          :active="isDialogVisible"
          :indeterminate="isDialogVisible"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-card-title>
          <span class="headline">Modifier {{ setting.label }}</span>
        </v-card-title>
        <v-alert
          v-model="isAlertVisible"
          color="error"
          text
          transition="slide-y-transition"
        >
          {{ message }}
        </v-alert>
        <v-form
          ref="form"
          @submit.prevent="submitForm"
        >
          <v-card-text>
            <div>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-textarea
                      v-if="setting.setting_types.code==='CHAR'"
                      v-model="setting.value"
                      rows="2"
                      type="number"
                      label="Valeur"
                      :rules="[rules.value]"
                    ></v-textarea>
                    <v-menu
                      v-else-if="setting.setting_types.code==='DATE'"
                      ref="menuref"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="setting.value"
                          label="Valeur"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="setting.value"
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1950-01-01"
                        color="primary"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                    <v-color-picker
                      v-else-if="setting.setting_types.code==='COLOR'"
                      v-model="setting.value"
                      mode="hexa"
                      :rules="[rules.value]"
                      width="450"
                    >
                    </v-color-picker>
                    <v-select
                      v-else
                      v-model="setting.value"
                      :items="response"
                      item-text="text"
                      item-value="value"
                      label="Choisir"
                      outlined
                      dense
                      placeholder="Choisir"
                      clearable
                      hide-details="auto"
                      persistent-hint
                      single-line
                      :rules="[rules.value]"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
              :disabled="isDialogVisible"
              :loading="isDialogVisible"
              color="success"
              type="submit"
            >
              Modifier
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getCurrentInstance, ref } from '@vue/composition-api/dist/vue-composition-api'
import { mdiCalendar, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import store from '@/store'

export default {
  name: 'SettingFormDialog',
  emits: ['clickout', 'submitted'],

  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-type-constructor
    dialog: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    setting: {
      id: null,
      label: null,
      key: null,
      value: null,
    },
  },

  setup(props) {
    const isPasswordVisible = ref(false)
    const isPasswordConfirmationVisible = ref(false)
    const date = ref(null)
    const menu = ref(false)
    const picker = ref(null)
    const menuref = ref(null)
    const form = ref(null)
    const message = ref('')
    const isDialogVisible = ref(false)
    const errors = ref('')
    const isAlertVisible = ref(false)
    const rules = {
      value: true,
    }
    const response = [
      { text: 'OUI', value: '1' },
      { text: 'NON', value: '0' },
    ]

    const instance = getCurrentInstance()

    const close = () => {
      isAlertVisible.value = false
      message.value = ''
      rules.value = true
      instance.emit('clickout')
    }

    const save = d => {
      picker.value.save(d)
    }

    const submitForm = () => {
      isAlertVisible.value = false
      isDialogVisible.value = true
      instance.emit('submitted')
      store.dispatch('setting/updateSetting', props.setting).then(() => {
        isDialogVisible.value = false
        close()
      }).catch(error => {
        form.value.validate()
        message.value = error.response.data.message
        errors.value = error.response.data.errors
        if (error.response.data.errors !== undefined) {
          rules.value = error.response.data.errors.value !== undefined ? error.response.data.errors.value[0] : true

          // rules.color = error.response.data.errors.value !== undefined ? error.response.data.errors.color[0] : true
          // rules.date = error.response.data.errors.value !== undefined ? error.response.data.errors.date[0] : true
        }
        isDialogVisible.value = false
        isAlertVisible.value = true
      })
      instance.emit('submitted')
    }

    return {
      response,
      isPasswordVisible,
      isPasswordConfirmationVisible,
      isAlertVisible,
      isDialogVisible,
      message,
      rules,
      submitForm,
      form,
      save,
      date,
      menu,
      picker,
      menuref,
      close,

      // icons
      icons: {
        mdiCalendar, mdiEyeOutline, mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
